import {BUSINESS_PLAN, COMPANY_STATUS, MARKET_ANALYSIS, UIN} from "@/helpers/constant/document";
import {
    ADMIN,
    ADMIN_,
    COMMERCIAL,
    COORDINATOR,
    COORDINATOR_,
    SUPER_ADMIN,
    SUPERVISOR, SUPERVISOR_,
    USER
} from "@/helpers/constant/roles";
import {ADMIN_PREFIX, COMMERCIAL_PREFIX, COORDINATOR_PREFIX, SUPERVISOR_PREFIX} from "@/helpers/constant/routePrefix";
import {SUPER_ADMIN_} from "../constant/roles";

export const getCreatedAndUpdatedDates = (createdAt) => {
    if (!createdAt) return null
    const date = createdAt.split('T')[0]
    const time = createdAt.split('T')[1]

    const hour = time.split(':')[0]
    const minute = time.split(':')[1]

    return date + ' ' + hour + ':' + minute
}

export const getCreatedTime = (createdAt) => {
    if (!createdAt) return null
    const time = createdAt.split('T')[1]

    const hour = time.split(':')[0]
    const minute = time.split(':')[1]

    return hour + ':' + minute
}

export const getApplicantFullNAme = (applicants) => {
    return applicants.length > 0 ? applicants[0].lastName + " " + applicants[0].firstName  : null
}

export const calculateAge = (birthDate) => {
    let birth = new Date(birthDate);
    let today = new Date();
    let age = today.getFullYear() - birth.getFullYear();
    let monthDiff = today.getMonth() - birth.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
        age--;
    }
    return age;
}

export const getDocumentNameFromType = (type) => {
    switch (type) {
        case UIN:
            return "Numéro d'Indentifiant Unique"
        case COMPANY_STATUS:
            return "Documents légaux de l'entreprise"
        case MARKET_ANALYSIS:
            return "Etude du marché"
        case BUSINESS_PLAN:
            return "Plan d'affaire détaillé"
        default:
            return ""
    }
}

export const formatedUser = (obj, propertiesToOmit) => {
    const formattedObject = {};
    for (let prop in obj) {
        if (!propertiesToOmit.includes(prop)) {
            formattedObject[prop] = obj[prop];
        }
    }
    return formattedObject;
}

export const getRoleString = (role) => {
    switch (role) {
        case ADMIN:
            return 'Admin'
        case SUPERVISOR:
            return 'Superviseur'
        case COORDINATOR:
            return 'Coordinateur'
        case SUPER_ADMIN:
            return 'Super Admin'
        case COMMERCIAL:
            return 'Commercial'
        case USER:
            return 'Membre'
        default:
            return 'Membre'
    }
}

export const getTruncateString = (string, num = 20) => {
    if (!string) return ""
    if (string.length > num){
        return string.substring(0, num) + "..."
    } else {
        return string
    }
}

export const getFormattedDate = (date) => {
    return date.split('T')[0]
}

export const getRelativeTime = (date) => {
    const currentDate = new Date()
    const createdDate = new Date(date)
    const diff = currentDate - createdDate
    const seconds = Math.floor(diff / 1000)
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)
    const months = Math.floor(days / 30)
    const years = Math.floor(months / 12)

    if (years > 0) {
        return years + " ans"
    } else if (months > 0) {
        return months + " mois"
    } else if (days > 0) {
        return days + " jours"
    } else if (hours > 0) {
        return hours + " Heures"
    } else if (minutes > 0) {
        return minutes + " Min"
    } else {
        return seconds + " Sec"
    }
}

const getAdminRoutesPrefix = (pageName) => {
    switch (pageName) {
        case 'supervisor':
            return ADMIN_PREFIX + '/supervisors/'
        case 'commercial':
            return ADMIN_PREFIX + '/commercials/'
        case 'members':
            return ADMIN_PREFIX + '/members/'
        case 'coordinator':
            return ADMIN_PREFIX + '/coordinators/'
        case 'account':
            return ADMIN_PREFIX + '/accounts/'
        default:
            return ADMIN_PREFIX + '/members/'

    }
}



const getCoordinatorRoutesPrefix = (pageName) => {
    switch (pageName) {
        case 'supervisor':
            return COORDINATOR_PREFIX + '/supervisors/'
        case 'commercial':
            return COORDINATOR_PREFIX + '/commercials/'
        case 'members':
            return COORDINATOR_PREFIX + '/members/'
        case 'account':
            return COORDINATOR_PREFIX + '/accounts/'
        default:
            return COORDINATOR_PREFIX + '/members/'

    }
}

const getSupervisorRoutesPrefix = (pageName) => {
    switch (pageName) {
        case 'commercial':
            return SUPERVISOR_PREFIX + '/commercials/'
        case 'members':
            return SUPERVISOR_PREFIX + '/members/'
        case 'account':
            return SUPERVISOR_PREFIX + '/accounts/'
        default:
            return SUPERVISOR_PREFIX + '/members/'

    }
}

const getCommercialRoutesPrefix = () => {
    return COMMERCIAL_PREFIX + '/members/'
}

export const getUrlPrefixToShow = (role, pageName) => {
    switch (role) {
        case SUPER_ADMIN_:
            return getAdminRoutesPrefix(pageName)
        case ADMIN_:
            return getAdminRoutesPrefix(pageName)
        case COORDINATOR_:
            return getCoordinatorRoutesPrefix(pageName)
        case SUPERVISOR_:
            return getSupervisorRoutesPrefix(pageName)
        default:
            return getCommercialRoutesPrefix(pageName)
    }
}